import { Comment, Layout, Post, SEO, SmallerContainer } from 'components/common'
import { graphql } from 'gatsby'
import React, { useEffect } from 'react'
import './highlight.css'

export default ({ data: { post }, pageContext }) => {
  const commentBox = React.createRef()

  useEffect(() => {
    const commentScript = document.createElement('script')
    const theme =
      typeof window !== 'undefined' && localStorage.getItem('theme') === 'dark'
        ? 'github-dark'
        : 'github-light'
    commentScript.async = true
    commentScript.src = 'https://utteranc.es/client.js'
    commentScript.setAttribute('repo', 'AkromDev/comments') // CHANGE THIS if you're just going to clone this repo and use the code. Do not test your code using my repo.
    commentScript.setAttribute('issue-term', 'pathname')
    commentScript.setAttribute('id', 'utterances')
    commentScript.setAttribute('theme', theme)
    commentScript.setAttribute('crossorigin', 'anonymous')
    if (commentBox && commentBox.current) {
      commentBox.current.appendChild(commentScript)
    } else {
      console.log(`Error adding utterances comments on: ${commentBox}`)
    }
  }, []) // eslint-disable-line

  return (
    <Layout>
      <SmallerContainer>
        <SEO
          type="NewsArticle"
          title={post.frontmatter.title}
          articleBody={post.description}
          datePublished={post.frontmatter.normalDate}
          dateModified={
            post.frontmatter.edited
              ? post.frontmatter.edited
              : post.frontmatter.date
          }
          cover={post.frontmatter.thumbnail.childImageSharp.fluid.originalImg}
          location={post.frontmatter.path}
          description={post.description}
          readTime={post.timeToRead}
        />
        <Post {...post} prev={pageContext.prev} next={pageContext.next}/>
        <div id="comments">
          <Comment commentBox={commentBox}/>
        </div>
      </SmallerContainer>
    </Layout>
  )
}

export const postQuery = graphql`
  query($path: String!) {
    post: markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      description: excerpt(pruneLength: 105)
      timeToRead
      frontmatter {
        normalDate: date
        date(formatString: "MMMM DD, YYYY")
        edited(formatString: "MMMM DD, YYYY")
        path
        title
        id
        tags
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 700) {
              originalImg
            }
          }
        }
      }
    }
  }
`
